import API from "../api";

export function getWarehouseOrders(params) {
    return API.get(`/api/warehouse/orders`, { params })
}

export function getWarehouses(storeId, params) {
    return API.get(`/api/commerces/${storeId}/warehouse-workers`, { params })
}

export function updateWarehouser(id, data) {
    return API.put(`/api/warehousers/${id}`, { ...data })
}

export function createWarehouser(data) {
    return API.post(`/api/warehousers`, { ...data })
} 

export function requestWarehouserOrder(commerceId, data) {
    return API.post(`/api/commerces/${commerceId}/warehouse-orders`, { ...data })
}

export function getStoresWithStock(params) {
    return API.get(`/api/stores-with-stock`, { params })
}

export function getTokenResource(params) {
    return API.post(`/api/users/${params.userId}/tokens/resources`)
}

export function getPromoters(params) {
    return API.get(`/api/promoters`, { params })
}

export function createPromoter(data) {
    return API.post(`/api/promoters`, { ...data })
}

export function updatePromoter(id, data) {
    return API.put(`/api/promoters/${id}`, { ...data })
}

export function getShopCart() {
    return API.post(`/api/shop-cart/products`)
}

export function updateShopCart(data) {
    return API.post(`/api/shop-cart/products`, { ...data })
}

export function getSalesOrderShippingPrice(data) {
    return API.post(`/api/sales-order/shipping-price`, { ...data })
}

export function createScanLog(params) {
    return API.post(`/api/scan-logs`, { ...params })
}

export function createSalesShopOrder(params) {
    return API.post(`/api/sales-order/payment-methods`, { ...params })
}

export function getSalesShopOrder(params) {
    return API.get(`/api/sales-shop-orders`, { params })
}

export function getStoreDisplays(params){
    return API.get(`/api/store-displays`, { params })
}

export function getStoresDisplayItems(id){
    return API.get(`/api/store-displays/${id}/items`)
}

export function getVariants2ByEan(params){
    return API.post(`/api/variants2-with-stock`, { ...params })
}